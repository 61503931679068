@import './variables.css';

.color-theme .about-me-title {
    color: var(--bright-red);
}
.color-theme .about-me-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.color-theme .about-me-paragraph {
    position: sticky;
    top: 74px;
    left: 9%;
    width: 82%;
    margin-left: 9%;
    margin-block: 10%;
    z-index: 2;
    color: var(--dark-red-text);
}

@media screen and (max-width: 992px) {
    .color-theme .about-me-paragraph {
        margin-top: 23%;
        margin-bottom: 25%;
    }
}

@media screen and (max-width: 600px) {
    .color-theme .about-me-paragraph {
        margin-top: 49%;
        margin-bottom: 57%;
    }
}