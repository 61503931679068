@import 'style/fonts.css';
@import './style/variables.css';

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-align: center;
  overflow-x: clip;
  font-size: 18px;
  -ms-overflow-style: none;  /* IE and Edge *!*/
  scrollbar-width: none;  /* Firefox *!*/
}
h1 {
  font-size: 120px;
  font-weight: 700;
}
h2 {
  font-size: 32px;
  font-weight: 600;
}
.tron-theme h1, .tron-theme h2 {
  font-family: 'Array-Bold', sans-serif;
  color: var(--tron-yellow);
  text-shadow: 5px 5px var(--tron-red);
}
.tron-theme h2 {
  text-shadow: 3px 3px var(--tron-red);
}
.color-theme h1, .color-theme h2 {
  font-family: 'Fredoka', sans-serif;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page {
  display:flex;
  flex-flow:column nowrap;
  justify-content: center;
  align-items: center;
  width: 84%;
  height: fit-content;
  background: none;
  margin-bottom: 60px;
}
/* Tron body theme */
.tron-theme {
  background: var(--tron-dark-blue);
  color: var(--tron-green);
  font-family: 'Tabular-Variable', monospace, sans-serif;
  cursor: url("assets/cursors/tron-cursor.svg"), auto;
}
/* Color body theme */
.color-theme {
  background: var(--cream);
  color: var(--black);
  font-family: 'Outfit', sans-serif;
  cursor: url("assets/cursors/color-cursor.svg"), auto;
}
.tron-theme a {
  text-decoration: none;
  cursor: url("assets/cursors/tron-pointer.svg"), pointer;
}
.color-theme a {
  text-decoration: none;
  cursor: url("assets/cursors/color-pointer.svg"), pointer;
}
.outline {
  color: var(--lime-outline) !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--lime-outline);
}
.color-theme .center-container {
  position: relative;
  height: auto;
  width: 78%;
}
.section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 102px;
}
@media screen and (max-width: 992px) {
  h1 {
    font-size: 70px;
  }
  .page {
    width: 88%;
  }
}