@import 'variables.css';

.footer {
    margin: auto;
    background: none;
    padding-top: 102px;
}
.text-footer {
    text-align: center;
    padding: 30px 0;
    font-size: 20pt;
    display: flex;
    justify-content: center;
}
.tron-theme .text-footer {
    font-family: 'Array-Regular', sans-serif;
    color: var(--tron-red);
    text-shadow: 1px 1px var(--tron-purple);
}
.color-theme .text-footer {
    font-family: 'Fredoka', sans-serif;
    color: var(--purple);
    font-weight: 600;
}