@import 'variables.css';

.switch {
    position: fixed;
    left: 32px;
    bottom: 32px;
    display: inline-block;
    width: 52px;
    height: 31px;
    z-index: 100;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--tron-dark-blue);
    border: 2px solid var(--cream);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 26px;
}
.color-theme .slider {
    cursor: var(--color-theme-cursor-pointer);
}
.tron-theme .slider {
    cursor: var(--tron-theme-cursor-pointer);
}
.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 4px;
    background-color: var(--hot-pink);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}
input:checked + .slider {
    background-color: var(--cream);
    border: 2px solid var(--tron-dark-blue);
}
input:focus + .slider {
    border: 2px solid var(--tron-dark-blue);
}
input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

@media screen and (max-width: 992px) {
    .switch {
        bottom: 22px;
        left: 22px;
    }
}