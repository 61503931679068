@import 'variables.css';

.color-theme #projects h2 {
    color: var(--baby-blue);
}
.projects-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5vw;
    margin-bottom: 50px;
    justify-content: space-around;
}
.project-box {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    text-align: left;
    width: 40%;
}
.color-theme .project-box {
    position: relative;
    margin-inline: 2% 3%;
    color: var(--dark-blue-text);
}
.tron-theme .project-box {
    background-color: var(--tron-muted-dark-blue);
    border: 3px solid var(--tron-purple);
    border-radius: 13px;
    padding-inline: 2% 3%;
}
.tron-theme .project-background {
    display: none;
}
.color-theme .project-background {
    position: absolute;
    height: 100%;
    width: 100%;
}
.project-header {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.color-theme .project-header {
    z-index: 2;
    width: 84%;
    margin-inline: 8%;
    margin-top: 8%;
}
.color-theme .project-content {
    margin: 0 8% 8%;
    z-index: 2;
}

@media screen and (max-width: 992px) {
    .projects-container {
        width: 96%;
        margin-top: 20px;
        gap: 4vh;
    }
    .project-box {
        width: 100%;
        padding-inline: 15px;
    }
    .tron-theme .project-content {
        margin-block: 4px 15px;
    }
    .color-theme .project-content {
        margin: 0 10% 15% 10%;
    }
}