@import './variables.css';

.to-top-btn {
    position: fixed;
    right: 0;
    padding: 16px;
    justify-content: end;
    align-content: end;
}
.color-theme .to-top-btn {
    bottom: 0;
}
.color-theme .to-top-btn * {
    bottom: 0;
    cursor: var(--color-theme-cursor-pointer);
}
.tron-theme .to-top-btn {
    transform: rotate(90deg);
    text-shadow: 2px 2px var(--tron-red);
    font-size: 26px;
    font-weight: 700;
    font-family: 'Array-Bold', sans-serif;
    bottom: 32px;
}
.tron-theme .to-top-btn * {
    cursor: var(--tron-theme-cursor-pointer);
}
.words {
    color: var(--tron-purple);
    text-decoration: none;
}

@media screen and (max-width: 992px) {
    .to-top-btn {
        visibility: hidden;
    }
}