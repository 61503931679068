@import './variables.css';

.color-theme .bordered-title {
    position: relative;
    width: 176px;
    height: 92px;
}
.color-theme .contact {
    position: absolute;
    top: 0;
    left: 0;
    color: var(--lime);
}
.color-theme .contact-container {
    position: relative;
    height: 240px;
}
.color-theme .contact-background {
    position: absolute;
    left: -120px;
}
.contact-items * {
    text-decoration: none;
}
.color-theme .contact-items {
    position: absolute;
    width: 201px;
    top: 69px;
    left: -100px;
    color: var(--dark-green-text);
}