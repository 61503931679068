@import 'variables.css';

.fun-title {
    color: var(--yellow);
}
.color-theme .fun-container {
    position: relative;
    height: 270px;
    width: 76%;
}
.color-theme .fun-background {
    position: absolute;
    top:0;
    left: 0;
    height: 270px;
    width: 100%;
    background-color: var(--muted-yellow);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.color-theme .fun-items {
    position: absolute;
    width: 100%;
    height: 100%;
}
.fun-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.color-theme .fun-items {
    color: var(--dark-yellow-text);
}

@media screen and (max-width: 992px) {
    .color-theme .fun-container {
        height: 320px;
        width: 98%;
    }
    .color-theme .fun-background {
        height: 320px;
    }
}