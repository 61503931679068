@font-face {
  font-family: 'Array-Regular';
  src: url('../assets/fonts/Array-Regular.woff2') format('woff2');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}
@font-face {
    font-family: 'Array-Semibold';
    src: url('../assets/fonts/Array-Semibold.woff2') format('woff2');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}
@font-face {
  font-family: 'Array-Bold';
  src: url('../assets/fonts/Array-Bold.woff2') format('woff2');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}
@font-face {
  font-family: 'Tabular-Variable';
  src: url('../assets/fonts/Tabular-Variable.woff2') format('woff2');
       font-weight: 300 700;
       font-display: swap;
       font-style: normal;
}
@font-face {
  font-family: 'Zodiak-Variable';
  src: url('../assets/fonts/Zodiak-Variable.woff2') format('woff2');
       font-weight: 300 700;
       font-display: swap;
       font-style: normal;
}