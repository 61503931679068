@import 'variables.css';

.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    margin: 0;
    padding: 0;
}
.nav-icon {
    height: 28px;
    width: 28px;
}
#_menuExit {
    position: absolute;
    top: 26px;
    right: 26px;
}
.nav-box {
    padding-inline: 2%;
    padding-block: 16px;
}
.tron-theme .nav-box, .tron-theme .nav-box:start {
    background: var(--tron-dark-blue);
}
.color-theme .nav-box {
    background: var(--cream);
}
.color-theme .nav-box:start {
    background: var(--cream);
}
.nav-list {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    -webkit-align-content: space-between;
}
.nav-list_cluster {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 48px;
}
.nav-list li {
    list-style: none;
}
.nav-list li a, .nav-list li {
    font-size: 32px;
    text-decoration: none;
}
.name {
    font-size: 44px !important;
}

.name, .name_normal, .name_outline {
    white-space: nowrap;
}
.tron-theme .nav-list li a {
    font-family: 'Array-Regular', sans-serif;
    color: var(--tron-purple);
    text-shadow: 2px 2px var(--tron-red);
    cursor: var(--tron-theme-cursor-pointer);
}
.tron-theme .nav-list li a:hover {
    color: var(--tron-yellow);
}
.tron-theme .nav-list li a:active {
    color: var(--tron-red);
    text-shadow: 2px 2px var(--tron-yellow);
}
.tron-theme .name {
    font-family: 'Array-Semibold', sans-serif;
    color: var(--tron-purple);
    text-shadow: 2px 2px var(--tron-red);
}
.color-theme .nav-list li a {
    font-family: 'Fredoka', sans-serif;
    font-weight: 600;
    cursor: var(--color-theme-cursor-pointer);
}
.color-theme .name {
    font-family: 'Fredoka', sans-serif;
    position: relative;
    height: 54px;
    font-weight: 600;
}
.color-theme .name_normal {
    position:absolute;
    top:0;
    left:0;
    color: var(--hot-pink);
}
.color-theme .name_outline {
    position:absolute;
    top:0;
    left:0;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--hot-pink-outline);
}
.color-theme .nav-projects {
    color: var(--bright-red);
}
.color-theme .nav-projects:hover {
    color: var(--bright-red-hover);
}
.color-theme .nav-resume {
    color: var(--purple);
}
.color-theme .nav-resume:hover {
    color: var(--purple-hover);
}
.color-theme .nav-fun {
    color: var(--baby-blue);
}
.color-theme .nav-fun:hover {
    color: var(--baby-blue-hover);
}

@media screen and (max-width: 992px) {
    .top-bar {
        display: flex;
        justify-content: flex-end;
        top: 0;
        width: 100vw;
    }
    .hamburger-menu {
        height: 28px;
        width: 28px;
        margin-right: 22px;
        margin-top: 22px;
        padding: 4px;
        border-radius: 3px;
    }
    .tron-theme .hamburger-menu {
        background-color: var(--tron-muted-dark-blue);
        border: 2px solid var(--tron-purple);
    }
    .color-theme .hamburger-menu {
        background-color: var(--cream);
        border: 2px solid var(--hot-pink);
    }
    .nav-box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        z-index: 101;
    }
    .tron-theme .nav-box {
        background-color: var(--tron-muted-dark-blue);
    }
    .nav-list_cluster {
        flex-direction: column;
    }
    .nav-list {
        margin-block: 10px;
        flex-direction: column;
        gap: 10px;
    }
    .name, .name_normal, .name_outline {
        display: none;
    }
    .nav-list li {
        width: auto;
    }
    .tron-theme .nav-list li a {
        font-size: 34px;
        text-shadow: 2px 2px var(--tron-red);
    }
    .color-theme .nav-list li a {
        font-size: 26px;
    }
}